import {chakra} from '@chakra-ui/react'

const Logo = () => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      width="full"
      height="full"
      viewBox="0 0 1167 475"
      sx={{
        '.st0': {
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#00A9CE'
        },
        '.st1': {
          fill: '#FFFFFF'
        },
        '.st2': {
          fill: 'none'
        },
        '.st3': {
          fill: '#C1C1C1'
        }
      }}>
      <g id="layer1" transform="translate(-41.732056,-64.283038)">
        <path
          id="Fill_1"
          className="st0"
          d="M467,114.2c-44.8-44.6-109.2-49.6-187.9-49.6c-78.8,0-143.2,5-188,49.8S41.8,223.5,41.8,302.2
		S46.3,445.3,91.1,490s109.2,49.3,188,49.3s143.2-4.5,188-49.3s49.3-109.1,49.3-187.9C516.5,223.4,511.9,158.9,467,114.2L467,114.2z
		"
        />
      </g>
      <path
        className="st1"
        d="M115.9,344.8c6.1,2.2,9.4,5.7,12.9,8.7c29.6,24.4,63.4,38.9,101.9,39.7c72.8,1.6,136.2-46.3,149.9-117.8
	c11.6-60.2-8.1-110.1-58-145.8c-60.1-43-143.5-29.9-188,28.6c-29.3,38.5-35.7,81.5-20.3,127.4c3.6,10.9,3.8,20.7-3.6,29.9
	c-6.3,7.8-14.7,10.8-24.5,9.5c-11-1.5-18.5-7.9-22.1-18.3c-5.3-15.4-8.9-31.3-9.8-47.5C51,199.9,70,148.8,114,108.6
	c39.7-36.4,87.2-52.9,141.3-47.5c80.5,8.1,144.1,64.6,159.8,142.9c19.4,96.8-40.6,189-136.9,207c-60.2,11.2-113.3-5.1-154.4-53
	c-2.1-2.5-3.8-5.4-5.6-8.1C117.5,348.7,117.1,347.4,115.9,344.8L115.9,344.8z"
      />
      <polygon
        className="st1"
        points="134,209.7 260.9,209.3 243.9,165.1 261.7,165.1 332.8,242.7 149.3,242.7 "
      />
      <polygon
        className="st1"
        points="350.7,281.6 223.9,282 240.8,326.2 223.1,326.2 152,248.5 335.4,248.5 "
      />
      <g>
        <rect
          x="542.8"
          y="132.9"
          className="st2"
          width="633.2"
          height="209.3"
        />
        <path
          className="st3"
          d="M551,321.2l12.9-24.7c11.2,10.6,32.4,17.9,48.5,17.9c14.4,0,23.8-6.8,23.8-15.9c0-26.5-80-7.9-80-61.2
		c0-26.8,23-45.3,55.9-45.3c20,0,40.9,5.6,53.8,17.4l-12.9,25.3c-11.5-10-32.1-15.3-44.1-15.3c-12.1,0-20,5.9-20,15.3
		c0,24.4,80.6,9.4,80.6,62.7c0,25.9-23.2,44.7-56.2,44.7C590.2,342.1,565.2,333.8,551,321.2z"
        />
        <path
          className="st3"
          d="M832.3,256.7v82.1h-33v-78c0-22.7-13.8-37.4-34.7-37.4c-21.5,0-35.6,14.7-35.6,37.4v78h-32.7V194.9H729v22.7
		c9.1-16.2,24.4-25.6,43.5-25.6C809.4,192,832.3,216.7,832.3,256.7z"
        />
        <path
          className="st3"
          d="M1001.5,274.7H892.1c3.5,23.2,19.4,37.4,42.7,37.4c16.2,0,30.9-7.1,37.7-18.2l26.2,16.8
		c-10.6,19.4-34.7,31.2-64.4,31.2c-41.5,0-74.7-32.7-74.7-75.6c0-41.8,32.7-74.1,74.1-74.1c38.8,0,69.1,30,69.1,68.6
		C1002.7,264.4,1002.4,270.3,1001.5,274.7z M968.3,249.7c-1.8-17.9-16.8-30.6-36.5-30.6s-33.5,11.8-38.5,30.6H968.3z"
        />
        <path
          className="st3"
          d="M1123.9,338.8l-61.2-68v68H1030v-206h32.7V257l58.3-62.1h40.9l-66.2,69.1l70.6,74.7H1123.9z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </chakra.svg>
  )
}

export default Logo
